import { api, getErrorReason } from '@/api'
import User from '@/entities/user'


const state = {
  dialogDelete: false,
  dialogEdit: false,
  dialogImport: false,
  drawerDetails: false,
  editedUser: null,
  loadingUsers: false,
  savingUser: false,
  selectedUserIri: null,
  users: [],
}


const mutations = {
  PATCH(state, patch) {
    Object.assign(state, patch)
  },
}


const actions = {
  async fetchUsers({ commit }, { silent } = {silent: false}) {
    if (!silent) commit('PATCH', {loadingUsers: true})
    const params = {
      pagination: false,
    }
    try {
      const response = await api.get('/api/users', {params})
      const users = response.data['hydra:member'].map((u) => new User(u))
      commit('PATCH', {users})
    } catch (e) {
      console.error(e)
    } finally {
      commit('PATCH', {loadingUsers: false})
    }
  },
  selectUser({ commit }, user) {
    const selectedUserIri = user?.['@id'] || null
    commit('PATCH', {
      selectedUserIri,
      drawerDetails: !!selectedUserIri,
    })
  },
  openDialog({ commit }, { dialog = 'edit', user = null } = {}) {
    commit('PATCH', {
      dialogDelete: 'delete' == dialog,
      dialogEdit: 'edit' == dialog,
      dialogImport: 'import' == dialog,
      editedUser: user,
    })
  },
  closeDialog({ commit }) {
    commit('PATCH', {
      dialogDelete: false,
      dialogEdit: false,
      dialogImport: false,
      editedUser: null,
    })
  },
  async createUser({ commit }, payload) {
    commit('PATCH', {savingUser: true})
    try {
      await api.post('/api/users', payload)
    } catch (e) {
      throw getErrorReason(e)
    } finally {
      commit('PATCH', {savingUser: false})
    }
  },
  async patchUser({ commit }, { user, patch }) {
    commit('PATCH', {savingUser: true})
    try {
      await api.put(user['@id'], patch)
    } catch (e) {
      throw getErrorReason(e)
    } finally {
      commit('PATCH', {savingUser: false})
    }
  },
  async removeUser({ commit }, user) {
    commit('PATCH', {savingUser: true})
    try {
      await api.delete(user['@id'])
    } catch (e) {
      throw getErrorReason(e)
    } finally {
      commit('PATCH', {savingUser: false})
    }
  },
}


const getters = {
  selectedUser({ selectedUserIri, users }) {
    return selectedUserIri && users.find((u) => u['@id'] === selectedUserIri) || null
  },
  teachersWithoutAccount(state, getters, rootState) {
    const teachersWithAccount = state.users.map((u) => u.teacher)
    const { teachers } = rootState.common
    return teachers.filter((t) => !teachersWithAccount.includes(t['@id']))
  },
  isCurrentUser(state, getters, rootState) {
    return (user) => user?.['@id'] === rootState.user?.['@id']
  },
}


export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
